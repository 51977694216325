
<script>
import { LoginMailByToken } from '@/api/client'
import { JumpMailClient, InitApiURL } from '@/utils/client'
import Cookies from 'js-cookie'
import config from '@/config/config.js'
export default {
    name: 'SSO',
    components: {},
    filters: {
    },
    data () {
        return {

        }
    },
    created () {
        const { token = '', mail = '', mail_id = 0, mail_type = '' } = this.$route.query
        if (token === '' || +mail_id === 0 || (mail_type !== 'domain_mail' && mail_type !== 'random_mail')) {
            this.$message.error(this.$t('jin-ru-shi-bai'))
            return
        }
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJaWUsiLCJpZCI6MywidGVsX251bSI6IjE4NTIyNjE3MDk0IiwibWFpbCI6IiIsInBhc3N3b3JkIjoiZTg4MjFiYjFhYjYxNTMzZjczNDExODIxNTk5NjI2NzUiLCJsb2dfdXNlcl90eXBlIjoiYWRtaW4iLCJsb2dfdXNlcl9pZCI6OTc4OCwid2VpcXVfYWRtaW4iOnRydWV9.5oJhFkfqivA5WaosDfcjnSWmLgkr_zCUoV1Mr-4egko'
        // const mail_id = '1694479442933294984'
        // const mail = 'aaaa'
        // const mail_type = 'random_mail'
        document.title = `${this.$t('zheng-zai-deng-lu')} ${mail}, ${this.$t('qing-shao-hou')}`

        this.loading = this.$loading({
            lock: true,
            text: `${this.$t('zheng-zai-deng-lu')} ${mail}, ${this.$t('qing-shao-hou')}`,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        this.loginByTokenMail(mail_type, mail_id, token)
    },
    methods: {
        // 通过token登录
        async loginByTokenMail (mail_type, mail_id, token) {
            try {
                const params = {
                    token,
                    mail_type
                }
                if (mail_type === 'domain_mail') {
                    params.mail_id = mail_id
                }
                if (mail_type === 'random_mail') {
                    params.random_mail_id = mail_id
                }
                const loginResponse = await LoginMailByToken(params)
                const { login_info = {}, api = {} } = loginResponse.data || {}

                // API地址
                const { api_url = '' } = api || {}
                if (api_url === '' || (mail_type !== 'domain_mail' && mail_type !== 'random_mail')) {
                    throw new Error(this.$t('wu-fa-huo-qu-qing-qiu-di-zhi'))
                }
                InitApiURL(mail_type, api_url)

                // 登录信息
                const { token_mail = '', token_rl = {} } = login_info || {}
                if (token_mail === '') {
                    throw new Error(this.$t('deng-lu-shi-bai-token-wei-kong'))
                }
                this.$message.success(this.$t('message.login_success'))
                Cookies.set(config.COOKIES.TOKEN_MAIL, token_mail, { expires: 7 })
                this.loading.close()
                JumpMailClient(token_rl)
            } catch (err) {
                console.error('LoginMailByToken Err:' + err)
                this.$message.error(err.msg || this.$t('token-deng-lu-shi-bai'))
                this.loading.close()
                throw err
            }
        }
    }
}
</script>

<style scoped>

</style>
